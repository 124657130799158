<template>
    <v-container id="section-11" fluid v-if="$vuetify.breakpoint.smAndDown">
        <v-row>
            <v-col
                class="text-h6 py-16 font-weight-regular text-center"
                style="letter-spacing: 2px !important"
                id="mobile-main"
            >
                {{ $t('section1.description') }}
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    methods: {},
};
</script>
<style>
#section-11 {
    background: rgb(43, 43, 43) no-repeat center center fixed !important;
    background-blend-mode: multiply;
    background-size: cover !important;
}
#mobile-main {
    line-height: 35px;
}
</style>
