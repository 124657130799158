const en = {
    navBar: {
        home: 'home',
        about: 'about',
        resume: 'resume',
        portfolio: 'portfolio',
        contact: 'contact',
    },
    section1: {
        title: "Lily's Piano Studio",
        description:
            "Music is a universal language that challenges the mind, sparks the imagination, and brings joy to all of our lives every day! The core value of Lily's Piano Studio is to provide students with professional tutoring while building self-confidence, strong foundations, and a love for music! All areas of study are covered in all course types; Theory, Notation, Hand/Eye Coordination, Technical Development, Improvisation and Performance. Students progress fast through their studies, and most importantly, they have fun! Lily is dedicated to her student’s music education, regardless of age or experience level. Join today and experience the joy and adventure that music has to offer!",
    },
    section2: {
        title: 'About',
        description:
            "Lily is a pianist, piano teacher, dedicated to the art musician, exploring all of its different perspectives. Born in China she began studying piano at the age of 6. Lily graduated with honors from Tianjin Conservatory Of Music (prof. Xiaoliang Lv), Tianjin, China. She continued her piano studies in the U.S. at the University of South Florida with prof. Eunmi Ko and obtained her master's degree in piano performance. With over 10 years of teaching, Lily is experienced not only in various examinations including RCM (The Royal Conservatory of Music), ABRSM (Associated Board of the Royal Schools of Music), SGECCM (Standard Grading Examination of the Central Conservatory of Music), and SGESCM (Standard Grading Examination of the Shanghai Conservatory of Music) but also in teaching across cultures and ages from China to North America, from age 4 to 75 in both Mandarin and English.",
    },
    section3: {
        education: 'Education',
        usf: 'University of South Florida',
        masters: 'Masters of Music in Piano Performance',
        us: 'Tampa, US',
        tcm: 'Tianjin Conservatory Of Music',
        bachelors: 'Bachelor of Music in Piano Performance',
        tianjin: 'Tianjin, CN',
        work: 'Work',
        lily: "Lily's Piano Studio",
        owner: 'Owner',
        ca: 'Winnipeg, CA',
        present: 'Present',
        schimmel: 'Schimmel Music Center',
        director: 'Director of Piano Department',
        shanghai: 'Shanghai, CN',
        musicAndMore: 'U.S. Music & More Art School',
        senior: 'Senior Piano Teacher',
        baisi: 'Tianjin Baisi Art Center',
        teacher: 'Piano Teacher',
    },
    section4: {
        certificates: 'Certificates',
        rcm: 'The Royal Conservatory of Music Certified Teacher',
        abrsm:
            'Associated Board of the Royal Schools of Music Certificate for Music Educators',
        mtna: 'Active Member of Music Teachers National Association',
        ccm:
            'Certified Teacher For Standard Grading Examination of the Central Conservatory of Music',
        juilliard:
            'Member of Juilliard International Musicians Association of North America',
        la:
            'Top Piano Teacher of 2019 Los Angeles International Youth Music Open Competition',
    },
    section5: {
        contact: 'Contact',
    },
    covid: {
        title: 'Covid-19 Information',
        content:
            "Due to the current restriction, increasing trends and personal reasons, we are unable to take any new students at the moment. If you are still interested, feel free to contact us to join the waiting list or to ask for other piano teachers that may be available. For current students, only one extra person is allowed to enter and masks are required. Thanks for your interest in Lily's Piano Studio and we apologize for any inconvenience.",
    },
};

export default en;
