<template>
    <v-footer inset class="justify-center pl-0">
        <v-container>
            <v-row>
                <v-col align="center">
                    <v-btn icon @click="goToTop()">
                        <v-icon x-large>mdi-chevron-up-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center" class="grey--text">
                    &copy; Copyright {{ currentYear }} Lily's Piano Studio
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<script>
export default {
    data: () => ({
        currentYear: new Date().getFullYear(),
    }),
    methods: {
        goToTop() {
            this.$vuetify.goTo(`#section-1`, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic',
            });
        },
    },
};
</script>
