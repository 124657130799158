<template>
    <v-container id="section-4" fluid class="black--text pt-12">
        <v-row>
            <v-col align="center" class="text-h5 grey--text py-8">
                {{ $t('section4.certificates') }}
            </v-col>
        </v-row>
        <v-item-group>
            <v-container>
                <v-row class="pt-10">
                    <v-spacer v-if="!isMobile"></v-spacer>
                    <v-col cols="12" md="3" align="center">
                        <v-hover v-slot="{ hover }" style="border-radius: 50%">
                            <v-item>
                                <v-img
                                    src="../assets/certificates/rcm.png"
                                    max-width="200px"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-body-2 white--text px-4"
                                            style="height: 100%;"
                                        >
                                            {{ $t('section4.rcm') }}
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-item>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="3" v-if="isMobile" align="center">
                        {{ $t('section4.rcm') }}
                    </v-col>
                    <v-col cols="12" md="3" align="center">
                        <v-hover v-slot="{ hover }" style="border-radius: 50%">
                            <v-item>
                                <v-img
                                    src="../assets/certificates/ABRSM.png"
                                    max-width="200px"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-body-2 white--text px-4"
                                            style="height: 100%;"
                                        >
                                            {{ $t('section4.abrsm') }}
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-item>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="3" v-if="isMobile" align="center">
                        {{ $t('section4.abrsm') }}
                    </v-col>
                    <v-col cols="12" md="3" align="center">
                        <v-hover v-slot="{ hover }" style="border-radius: 50%">
                            <v-item>
                                <v-img
                                    src="../assets/certificates/mtna.png"
                                    max-width="200px"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-body-2 white--text px-4"
                                            style="height: 100%;"
                                        >
                                            {{ $t('section4.mtna') }}
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-item>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="3" v-if="isMobile" align="center">
                        {{ $t('section4.mtna') }}
                    </v-col>
                    <v-spacer v-if="!isMobile"></v-spacer>
                </v-row>
                <v-row class="pb-10">
                    <v-spacer v-if="!isMobile"></v-spacer>
                    <v-col cols="12" md="3" align="center">
                        <v-hover v-slot="{ hover }" style="border-radius: 50%">
                            <v-item>
                                <v-img
                                    src="../assets/certificates/CCM.png"
                                    max-width="200px"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-body-2 white--text px-4"
                                            style="height: 100%;"
                                        >
                                            {{ $t('section4.ccm') }}
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-item>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="3" v-if="isMobile" align="center">
                        {{ $t('section4.ccm') }}
                    </v-col>
                    <v-col cols="12" md="3" align="center">
                        <v-hover v-slot="{ hover }" style="border-radius: 50%">
                            <v-item>
                                <v-img
                                    src="../assets/certificates/Juilliard.png"
                                    max-width="200px"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-body-2 white--text px-4"
                                            style="height: 100%;"
                                        >
                                            {{ $t('section4.juilliard') }}
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-item>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="3" v-if="isMobile" align="center">
                        {{ $t('section4.juilliard') }}
                    </v-col>
                    <v-col cols="12" md="3" align="center">
                        <v-hover v-slot="{ hover }" style="border-radius: 50%">
                            <v-item>
                                <v-img
                                    src="../assets/certificates/LA.png"
                                    max-width="200px"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-body-2 white--text px-4"
                                            style="height: 100%;"
                                        >
                                            {{ $t('section4.la') }}
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-item>
                        </v-hover>
                    </v-col>
                    <v-col cols="12" md="3" v-if="isMobile" align="center">
                        {{ $t('section4.la') }}
                    </v-col>
                    <v-spacer v-if="!isMobile"></v-spacer>
                </v-row>
            </v-container>
        </v-item-group>
    </v-container>
</template>
<script>
export default {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    methods: {},
    data: () => ({}),
};
</script>
<style>
#section-4 {
    background: rgb(229, 229, 230) no-repeat center center fixed;
}
.v-card--reveal {
    align-items: center;
    bottom: 0;
    opacity: 0.9;
    justify-content: center;
    position: absolute;
    width: 100%;
}
</style>
