import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from '@/locales/locales.js';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: locales,
});

export default i18n;
