const zh = {
    navBar: {
        home: '主页',
        about: '关于',
        resume: '履历',
        portfolio: '证书',
        contact: '联系',
    },
    section1: {
        title: 'Lily 钢琴工作室',
        description:
            '音乐是一门国际通用语言，它挑战思想，激发想象，并为人们带来快乐! Lily 钢琴工作室的核心理念是为学生提供专业的钢琴教学，同时培养学生的自信心并建立坚实的基础以及对音乐的热爱! 所有的课程类型都涵盖了：理论、记谱、手/眼协调、技巧、即兴演奏和表演。无论学生的年龄或经验水平如何，Lily 总是致力于将教学过程寓教于乐。今天就加入，体验音乐所带来的快乐。',
    },
    section2: {
        title: '关于',
        description:
            'Lily 是一位钢琴家，教育家，她始终致力于从多角度探索艺术。她出生在中国，6 岁开始学习钢琴，以优异的成绩毕业于天津音乐学院（师从吕晓亮教授）。她在美国南佛罗里达大学跟随 Eunmi Ko 教授继续深造并取得钢琴表演硕士学位。Lily 拥有超过 10 年的钢琴教学经验，她不仅在辅导各种钢琴等级考试方面经验丰富，包括 RCM（加拿大皇家音乐学院等级考试）、ABRSM（英国皇家音乐学院联合委员会等级考试）、SGECCM（中央音乐学院标准分级考试）和 SGESCM（上海音乐学院标准分级考试），而且她在从中国到北美，从 4 岁到 75 岁的不同文化和年龄段的普通话和英语教学方面都有丰富的经验。',
    },
    section3: {
        education: '教育背景',
        usf: '南佛罗里达大学',
        masters: '钢琴表演硕士',
        us: '坦帕，美国',
        tcm: '天津音乐学院',
        bachelors: '钢琴表演学士',
        tianjin: '天津，中国',
        work: '工作经历',
        lily: 'Lily 钢琴工作室',
        owner: '所有人',
        ca: '温尼伯，加拿大',
        present: '目前',
        schimmel: '舒梅尔音乐中心',
        director: '钢琴部主任',
        shanghai: '上海，中国',
        musicAndMore: '美国 Music & More 音乐学院',
        senior: '资深钢琴教师',
        baisi: '天津柏斯音乐中心',
        teacher: '钢琴教师',
    },
    section4: {
        certificates: '认证',
        rcm: '加拿大皇家音乐学院等级考试认证教师',
        abrsm: '英国皇家音乐学院联合委员会等级考试注册教师',
        mtna: '美国音乐教师协会成员',
        ccm: '中央音乐学院标准分级考试认证教师',
        juilliard: '北美茱莉亚国际音乐家协会成员',
        la: '2019 年洛杉矶国际青少年音乐公开赛最佳钢琴教师',
    },
    section5: {
        contact: '联系',
    },
    covid: {
        title: '关于新型冠状病毒肺炎的通知',
        content:
            '由于目前的聚集限制令，逐渐增长的病例趋势以及个人原因，我们目前无法接收任何新的学生。如果您仍然有兴趣，请随时与我们联系，加入等候名单或询问其他钢琴教师。对于现有的学生，只允许多一个人进入，并且需要戴口罩。感谢您对 Lily 钢琴工作室的兴趣，我们对带来的不便表示歉意。',
    },
};

export default zh;
