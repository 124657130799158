<template>
    <v-container id="section-1" fluid v-if="$vuetify.breakpoint.mdAndUp">
        <v-row align="center" style="height: 30%">
            <v-col align="center">
                <h1 class="text-h1 font-weight-medium">
                    {{ $t('section1.title') }}
                </h1>
            </v-col>
        </v-row>
        <v-row style="height: 50%">
            <v-col
                class="mx-16 px-16 text-h6 font-weight-light text-center"
                id="description"
            >
                {{ $t('section1.description') }}
            </v-col>
        </v-row>
        <v-row style="height: 20%" align="center">
            <v-col align="center">
                <v-btn icon x-large @click="goToNext(2)">
                    <v-icon x-large>
                        mdi-chevron-down-circle
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else id="section-1" fluid>
        <v-row>
            <v-col>
                <h1 class="text-h1 font-weight-medium">
                    {{ $t('section1.title') }}
                </h1>
            </v-col>
        </v-row>
        <v-row style="height: 100%" align="center">
            <v-col align="center">
                <v-btn icon x-large @click="goToNext(11)">
                    <v-icon x-large>
                        mdi-chevron-down-circle
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    methods: {
        goToNext(e) {
            this.$vuetify.goTo(`#section-${e}`, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic',
            });
        },
    },
};
</script>
<style>
#section-1 {
    background: url('../assets/bg.jpg') no-repeat center center fixed
        rgba(0, 0, 0, 0.6) !important;
    background-blend-mode: multiply;
    background-size: cover !important;
    height: 100vh !important;
}
#description {
    line-height: 50px;
    letter-spacing: 1px !important;
}
@media only screen and (max-width: 600px) {
    #section-1 {
        background: url('../assets/bg.jpg') no-repeat 30% center scroll
            rgba(0, 0, 0, 0.6) !important;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
    }
}
</style>
