<template>
    <v-app-bar app dark elevate-on-scroll style="opacity: 0.9" color="black">
        <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown"
            @click="$emit('toggleMenu')"
        ></v-app-bar-nav-icon>
        <v-row v-else>
            <v-col align="center">
                <v-btn
                    text
                    plain
                    :ripple="false"
                    v-for="(button, index) in buttons"
                    :key="index"
                    @click="goToSection(index + 1)"
                >
                    {{ button }}
                </v-btn>
                <v-btn
                    text
                    plain
                    :ripple="false"
                    href="https://www.youtube.com/channel/UC3nG3sfz3kO8duL6Ce5qbyA/featured"
                    target="_blank"
                >
                    <v-icon class="pr-2">mdi-youtube</v-icon>
                    youtube
                </v-btn>
                <v-menu open-on-hover bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text dark v-bind="attrs" v-on="on">
                            <flag
                                :iso="isLocaleEn ? 'ca' : 'cn'"
                                v-bind:squared="false"
                            />
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="$emit('changeLocale')">
                            <flag
                                :iso="isLocaleEn ? 'cn' : 'ca'"
                                v-bind:squared="false"
                                style="margin: auto"
                            />
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-app-bar>
</template>
<script>
import i18n from '@/plugins/i18n';
export default {
    data: () => ({
        buttons: [],
        i18n,
    }),
    computed: {
        isLocaleEn() {
            return i18n.locale.includes('en');
        },
    },
    methods: {
        goToSection(sectionId) {
            this.$vuetify.goTo(`#section-${sectionId}`, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic',
            });
        },
    },
    beforeMount() {
        this.buttons = [
            this.$t('navBar.home'),
            this.$t('navBar.about'),
            this.$t('navBar.resume'),
            this.$t('navBar.portfolio'),
            this.$t('navBar.contact'),
        ];
    },
};
</script>
