<template>
  <v-app>
    <NavBar
      @toggleMenu="showMenu = !showMenu"
      @changeLocale="changeLocale()"
      :key="reload"
    />
    <MobileMenu
      :toggleMenu="showMenu"
      @changeLocale="changeLocaleOnMobile($event)"
      :key="reloadMobileMenu"
    />
    <v-main>
      <component v-for="i in sections" :key="i" v-bind:is="`Section${i}`" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar';
import Section1 from '@/components/Section1';
import Section11 from '@/components/Section11';
import Section2 from '@/components/Section2';
import Section3 from '@/components/Section3';
import Section4 from '@/components/Section4';
import Section5 from '@/components/Section5';
import Footer from '@/components/Footer';
import MobileMenu from '@/components/MobileMenu';
import i18n from '@/plugins/i18n';
export default {
  name: 'App',
  components: {
    NavBar,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section11,
    Footer,
    MobileMenu,
  },
  data: () => ({
    sections: [1, 11, 2, 3, 4, 5],
    showMenu: false,
    reload: false,
    reloadMobileMenu: true,
  }),
  methods: {
    changeLocale() {
      i18n.locale == 'en' ? (i18n.locale = 'zh') : (i18n.locale = 'en');
      this.reload = !this.reload;
      this.reloadMobileMenu = !this.reloadMobileMenu;
    },
    changeLocaleOnMobile(locale) {
      i18n.locale = locale;
      this.reloadMobileMenu = !this.reloadMobileMenu;
      this.reload = !this.reload;
    },
  },
};
</script>
<style></style>
