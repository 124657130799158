<template>
    <v-container id="section-5" fluid class="pt-12">
        <v-row>
            <v-col align="center" class="text-h4 pt-8 pb-4">
                {{ $t('section5.contact') }}
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                43 Beachgrove Crt
                <br />
                Winnipeg, MB R3Y 0W6
                <br />
                <a href="tel:2048818915">204-951-0608</a>
                <br />
                <a href="mailto:contact@lilypianostudio.com">
                    contact@lilypianostudio.com
                </a>
            </v-col>
        </v-row>
        <v-row style="height: 50vh">
            <v-spacer v-if="!isMobile"></v-spacer>
            <v-col cols="12" md="6">
                <MglMap
                    :accessToken="accessToken"
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    :center="coordinates"
                    :zoom="zoom"
                >
                    <MglMarker :coordinates="coordinates" color="blue" />
                </MglMap>
            </v-col>
            <v-spacer v-if="!isMobile"></v-spacer>
        </v-row>
    </v-container>
</template>
<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker } from 'vue-mapbox';
export default {
    components: {
        MglMap,
        MglMarker,
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    methods: {},
    data: () => ({
        accessToken: process.env.VUE_APP_MAPBOX_KEY,
        coordinates: [-97.193719, 49.784811],
        zoom: 12,
    }),
    created() {
        this.mapbox = Mapbox;
    },
};
</script>
<style>
#section-5 {
    height: 90vh !important;
    background: rgb(0, 0, 0) no-repeat center center fixed;
}
</style>
