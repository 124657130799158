<template>
    <v-container id="section-3" fluid class="black--text pt-12" v-if="isMobile">
        <v-row
            v-for="(item, index) in content"
            :key="index"
            :class="index == 0 ? 'pt-4' : 'pb-8'"
        >
            <v-col align="center" cols="12">
                <div class="text-h4" id="edu-title-1">
                    {{ item.title }}
                </div>
            </v-col>
            <v-col cols="12">
                <v-list-item
                    two-line
                    v-for="(i, idx) in item.items"
                    :key="idx"
                    class="pb-6"
                >
                    <v-list-item-content>
                        <v-list-item-title class="black--text text-h5">
                            {{ i.title }}
                            <br />
                            <span class="font-italic text-body-2">
                                ({{ i.place }})
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            class="black--text text-body-1 pt-4"
                        >
                            {{ i.subtitle }}
                            <br />
                            <span class="font-italic text-body-2">
                                {{ i.year }}
                            </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
    </v-container>
    <v-container id="section-3" fluid class="black--text pt-12" v-else>
        <v-row
            v-for="(item, index) in content"
            :key="index"
            :class="index == 0 ? 'pt-16' : 'pb-16'"
        >
            <v-col align="right" cols="4">
                <div class="text-h5" id="edu-title-1">
                    {{ item.title }}
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="7">
                <v-list-item two-line v-for="(i, idx) in item.items" :key="idx">
                    <v-list-item-content>
                        <v-list-item-title class="black--text text-h5">
                            {{ i.title }}
                            <span class="font-italic text-body-2"
                                >({{ i.place }})</span
                            >
                        </v-list-item-title>
                        <v-list-item-subtitle
                            class="black--text text-body-1 pt-4"
                        >
                            {{ i.subtitle }} • {{ i.year }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import i18n from '@/plugins/i18n';
export default {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    watch: {
        'i18n.locale': {
            handler() {
                this.content = [
                    {
                        title: this.$t('section3.education'),
                        items: [
                            {
                                title: this.$t('section3.usf'),
                                subtitle: this.$t('section3.masters'),
                                place: this.$t('section3.us'),
                                year: '2016',
                            },
                            {
                                title: this.$t('section3.tcm'),
                                subtitle: this.$t('section3.bachelors'),
                                place: this.$t('section3.tianjin'),
                                year: '2014',
                            },
                        ],
                    },
                    {
                        title: this.$t('section3.work'),
                        items: [
                            {
                                title: this.$t('section3.lily'),
                                subtitle: this.$t('section3.owner'),
                                place: this.$t('section3.ca'),
                                year: this.$t('section3.present'),
                            },
                            {
                                title: this.$t('section3.schimmel'),
                                subtitle: this.$t('section3.director'),
                                place: this.$t('section3.shanghai'),
                                year: '2017 - 2019',
                            },
                            {
                                title: this.$t('section3.musicAndMore'),
                                subtitle: this.$t('section3.senior'),
                                place: this.$t('section3.us'),
                                year: '2015 - 2017',
                            },
                            {
                                title: this.$t('section3.baisi'),
                                subtitle: this.$t('section3.teacher'),
                                place: this.$t('section3.tianjin'),
                                year: '2010 - 2014',
                            },
                        ],
                    },
                ];
            },
        },
    },
    methods: {},
    data: () => ({
        content: [],
        i18n,
    }),
    beforeMount() {
        this.content = [
            {
                title: this.$t('section3.education'),
                items: [
                    {
                        title: this.$t('section3.usf'),
                        subtitle: this.$t('section3.masters'),
                        place: this.$t('section3.us'),
                        year: '2016',
                    },
                    {
                        title: this.$t('section3.tcm'),
                        subtitle: this.$t('section3.bachelors'),
                        place: this.$t('section3.tianjin'),
                        year: '2014',
                    },
                ],
            },
            {
                title: this.$t('section3.work'),
                items: [
                    {
                        title: this.$t('section3.lily'),
                        subtitle: this.$t('section3.owner'),
                        place: this.$t('section3.ca'),
                        year: this.$t('section3.present'),
                    },
                    {
                        title: this.$t('section3.schimmel'),
                        subtitle: this.$t('section3.director'),
                        place: this.$t('section3.shanghai'),
                        year: '2017 - 2019',
                    },
                    {
                        title: this.$t('section3.musicAndMore'),
                        subtitle: this.$t('section3.senior'),
                        place: this.$t('section3.us'),
                        year: '2015 - 2017',
                    },
                    {
                        title: this.$t('section3.baisi'),
                        subtitle: this.$t('section3.teacher'),
                        place: this.$t('section3.tianjin'),
                        year: '2010 - 2014',
                    },
                ],
            },
        ];
    },
};
</script>
<style>
#section-3 {
    background: white no-repeat center center fixed;
}
#edu-title-1,
#edu-title-2 {
    text-decoration: underline;
    text-decoration-color: rgb(0, 110, 255);
    text-decoration-thickness: 3px;
    text-underline-offset: 3px;
    letter-spacing: 2px !important;
}
</style>
