<template>
    <v-container id="section-2" fluid>
        <v-row align="center">
            <v-col align="center" class="pt-12">
                <v-img
                    src="../assets/head.jpg"
                    contain
                    :width="isMobile ? '70%' : '20%'"
                    style="border-radius: 50%"
                ></v-img>
            </v-col>
        </v-row>
        <v-row align="center" style="height: 10%">
            <v-col
                align="center"
                :class="`pt-8 text-${isMobile ? 'h3' : 'h4'}`"
            >
                {{ $t('section2.title') }}
            </v-col>
        </v-row>
        <v-row>
            <v-col
                :class="
                    `${
                        isMobile ? 'py-10 ' : 'mx-16 pa-16 '
                    }text-h6 font-weight-light text-center`
                "
                id="about-description"
            >
                {{ $t('section2.description') }}
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
};
</script>
<style>
#about-description {
    line-height: 35px;
    letter-spacing: 2px !important;
}
</style>
