import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from '@/plugins/i18n';
import FlagIcon from 'vue-flag-icon';

Vue.use(FlagIcon);

Vue.config.productionTip = false;

Vue.directive('blur', {
    inserted: function(el) {
        el.onfocus = ev => ev.target.blur();
    },
});

new Vue({
    i18n,
    vuetify,
    render: h => h(App),
}).$mount('#app');
