<template>
    <v-navigation-drawer
        app
        v-model="drawer"
        width="85%"
        color="black"
        v-if="$vuetify.breakpoint.smAndDown"
    >
        <v-list nav class="pt-14 pl-8">
            <v-list-item v-for="(item, index) in items" :key="index" link>
                <v-list-item-content>
                    <v-list-item-title
                        style="letter-spacing: 3px"
                        @click="goToSection(index + 1)"
                    >
                        {{ item }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link>
                <v-btn
                    text
                    plain
                    :ripple="false"
                    href="https://www.youtube.com/channel/UC3nG3sfz3kO8duL6Ce5qbyA/featured"
                    target="_blank"
                    class="pl-0"
                    v-blur
                    @click="drawer = false"
                >
                    <v-icon class="pr-2">mdi-youtube</v-icon>
                    youtube
                </v-btn>
            </v-list-item>
            <v-list-item>
                <span class="mx-auto">
                    <v-btn
                        text
                        dark
                        v-for="flag in flags"
                        :key="flag.flag"
                        @click="$emit('changeLocale', flag.lan)"
                    >
                        <flag :iso="flag.flag" v-bind:squared="false" />
                    </v-btn>
                </span>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import i18n from '@/plugins/i18n';
export default {
    props: {
        toggleMenu: {
            type: Boolean,
        },
    },
    watch: {
        toggleMenu() {
            this.drawer = !this.drawer;
        },
    },
    data: () => ({
        drawer: false,
        items: [],
        i18n,
        flags: [
            { flag: 'ca', lan: 'en' },
            { flag: 'cn', lan: 'zh' },
        ],
    }),
    methods: {
        goToSection(sectionId) {
            this.$vuetify.goTo(`#section-${sectionId}`, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic',
            });
            this.drawer = false;
        },
    },
    beforeMount() {
        this.items = [
            this.$t('navBar.home'),
            this.$t('navBar.about'),
            this.$t('navBar.resume'),
            this.$t('navBar.portfolio'),
            this.$t('navBar.contact'),
        ];
    },
};
</script>
